import React, { useState, useEffect, Suspense } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import MyRoutes from './MyRoutes';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faHome, faUserFriends, faBurn, faDollarSign, faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from '../assets/scripts/hooksLib';
import { AppContext } from '../assets/scripts/contextLib';
import { getPostsByStatus } from '../app/libs/Api/posts';
import config from '../config';
import { Loading } from '../components/Loading';
import InternalFooter from '../components/InternalFooter';
import '../assets/styles/me.scss';

const ErrorModal = React.lazy(() => import('../app/components/ErrorMessage'));

/**
 * Main Stitchz.net entry
 * @returns {HTMLElement} html for the main entry
 */
function Me() {
    const router = useRouter('/me');
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [hasAlerts, setHasAlerts] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);

    /**
     * Sets the Error Modal Window with error message and context
     * @param {string} title error message window title
     * @param {string} message error messsage
     * @param {[string]} level (optional) level of severity, e.g. danger, warning, info, etc.
     * @param {[string]} buttonTxt (optional) text label on button
     * @returns {void}
     */
    const setErrorOccurred = (title, message, level = 'danger', buttonTxt = 'OK') => {
        setErrorMessage({
            title: title,
            message: message,
            level: level,
            buttonText: buttonTxt
        });
        setShowErrorModal(true);
    };

    /**
     * Switches the Error modal window visibility to true or false
     * @returns {void}
     */
    const showErrorMessageModal = () => {
        if (showErrorModal) {
            setShowErrorModal(false);
            setErrorMessage({});
        } else {
            setShowErrorModal(true);
        }
    };

    // load user authentication state
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                const session = await fetchAuthSession();

                if (!session.tokens) {
                    location.href = `/login.html?redirect=${window.location.pathname}`;
                }

                userHasAuthenticated(true);

                setUser(await fetchUserAttributes());

                if (session.tokens?.idToken && session.tokens?.idToken.payload && session.tokens?.idToken?.payload['cognito:groups']) {
                    setIsAdmin(session.tokens?.idToken.payload['cognito:groups'].indexOf('Administrators') > -1);
                }
            } catch (e) { // eslint-disable-line no-unused-vars
                if (e !== 'No current user') {
                    // onError(e);
                    setErrorOccurred('Unknown User', e);
                } else {
                    location.href = `/login.html?redirect=${window.location.pathname}`;
                }
            }

            setIsAuthenticating(false);
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [setUser, userHasAuthenticated]);

    // load ERRORED posts
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            if (router.query && router.query.id && router.query.grpId) {
                try {
                    const { status, message, body } = await getPostsByStatus(router.query.id, router.query.grpId, config.postStatus.ERRORED, 'scheduledPostDate', 'scheduledPostDate')
                        .catch(err => {
                            return {
                                status: 'failed',
                                message: err.response && err.response.data ? err.response.data.message : err.message
                            };
                        });

                    if (status !== 'OK') {
                        // onError(message);
                        setErrorOccurred('An Error Occurred', message);
                    } else {
                        setHasAlerts(body && body.Items.length > 0);
                    }
                } catch (e) {
                    // console.error(e);
                    setErrorOccurred('An Unexpected Error Occurred', e);
                } finally {
                    // setPageLoading(false);
                }
            }
        };

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, []);

    /**
     * Get's the current location and add it to the redirect url
     * @returns {string} redirect url
     */
    const getCurrentLocation = () => {
        const path = window.location.pathname;

        if (path !== '/' && path !== '/login.html' && path !== '/signup.html') {
            return <a href={`../login.html?redirect=${encodeURIComponent(path)}`} rel='nofollow'>
                <Nav.Link>Login</Nav.Link>
            </a>;
        }

        return <a href='/login.html' rel='nofollow'>
            <Nav.Link>Login</Nav.Link>
        </a>;
    }

    return (
        <>
            {!isAuthenticating &&
                <>
                    <Container className='App' fluid="true">
                        <Navbar collapseOnSelect id="main-nav">
                            <Navbar.Brand>
                                <span className='stitchz-fonts-stitchz stitchz-6x' />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls='basic-navbar-nav' />
                            <Navbar.Collapse id='basic-navbar-nav'>
                                <Nav className='me-auto'>
                                    <a href='/' className='nav-link'><FontAwesomeIcon icon={faHome} /> Home</a>
                                    <a href='/features.html' className='nav-link'><FontAwesomeIcon icon={faBurn} /> Features</a>
                                    <a href='/pricing.html' className='nav-link'><FontAwesomeIcon icon={faDollarSign} /> Pricing</a>
                                    {isAuthenticated
                                        ?
                                        <Nav.Link href='/app' rel='nofollow'><FontAwesomeIcon icon={faUserFriends} /> Apps</Nav.Link>
                                        :
                                        <></>
                                    }
                                    {isAdmin ?
                                        <Nav.Link href='/admin' rel='nofollow'><FontAwesomeIcon icon={faUsersCog} /> Admin</Nav.Link>
                                        :
                                        <></>
                                    }
                                </Nav>
                                <Nav className='justify-content-end'>
                                    {isAuthenticated
                                        ?
                                        <>
                                            {user ?
                                                <div className="nav-item">
                                                    <a href="/me" data-rr-ui-event-key="/me" className="nav-link" rel='nofollow'>{user.name}</a>
                                                </div>
                                                :
                                                ''
                                            }
                                            <Navbar.Text title='Logout'>
                                                <a href="/logout.html" rel='nofollow'>
                                                    <FontAwesomeIcon icon={faPowerOff} />
                                                </a>
                                            </Navbar.Text>
                                        </>
                                        : <>
                                            <Navbar.Text>
                                                <a href='/signup.html' rel='nofollow'>
                                                    <Nav.Link>Signup</Nav.Link>
                                                </a>
                                            </Navbar.Text>
                                            <Navbar.Text>
                                                {getCurrentLocation()}
                                            </Navbar.Text>
                                        </>
                                    }
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
                            <MyRoutes hasAlerts={hasAlerts} />
                        </AppContext.Provider>
                    </Container>
                    <InternalFooter />
                </>
            }
            {errorMessage ?
                <Suspense fallback={<Loading pageLoading={true} />}>
                    <ErrorModal message={errorMessage} show={showErrorModal} handleClose={showErrorMessageModal} />
                </Suspense>
                : ''
            }
        </>
    );
}

export default Me;
